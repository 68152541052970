<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import Title from '@/Components/Title/Title.vue';

const route = inject('route');
const props = defineProps({
    showing: Boolean,
    shift: Object,
});

const emits = defineEmits(['update:showing']);

const closeForm = () => emits('update:showing', false);

const form = useForm({ shift_id: '' });

const sendForm = () => {
    form.shift_id = props.shift.id;

    form.post(route('location.retracted-shifts.store'), {
        onSuccess: closeForm,
    });
};
</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            <Title :title="$t('Retract {model}', { model: $t('shift') })" />
            <p v-if="shift.status == 'open'" class="text-base modal-description text-blue">
                {{ $t(`Are you sure you want to cancel this shift?`) }}
            </p>
            <p v-else class="text-base modal-description text-blue">
                {{
                    $t(
                        `Are you sure you want to cancel this shift? You have already assigned someone to this shift and are therefore entitled to the scheduled hours, this decision may affect your rating and future shifts.`
                    )
                }}
            </p>
        </template>
        <template #button-bar>
            <Button danger @click="sendForm" :processing="form.processing" :recentSuccessful="form.recentSuccessful">
                {{ $t('Retract') }}
            </Button>
            <Button orange @click="closeForm">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
